import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  //Landing
  {
    path: '/',
    component: () => import('../components/layouts/main.layout.vue'),
    children: [

      {
        path: "",
        name: 'landing',
        redirect: { name: 'home' },
      },

      {
        path: "/home",
        name: 'home',
        redirect: { name: 'portal-signin' }
      },
      // {
      //   path: "/privacy",
      //   name: 'privacy',
      //   component: () => import('../pages/privacy.page.vue'),
      // },
      // {
      //   path: "/terms",
      //   name: 'terms',
      //   component: () => import('../pages/terms.page.vue'),
      // },
      // {
      //   path: "",
      //   name: 'landing',
      //   redirect: { name: 'home' },
      // },
      // {
      //   name: 'home',
      //   path: "/home",
      //   component: () => import('../pages/index.page.vue'),
      // },

    ]
  },
  //Portal
  {
    path: '/portal',
    component: () => import('../components/layouts/plain.layout.vue'),
    children: [
      {
        path: "",
        name: 'portal-home',
        redirect: { name: 'portal-signin' }
      },
      {
        name: 'portal-signin',
        path: "/portal/signin",
        component: () => import('../pages/portal/signin.page.vue'),
      },
      {
        name: 'portal-signup',
        path: "/portal/signup",
        component: () => import('../pages/portal/signup.page.vue'),
      },
      {
        name: 'portal-forgot-password',
        path: "/portal/forgot-password",
        component: () => import('../pages/portal/forgot-password.page.vue'),
      },
    ]
  },
  //Manager
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../components/layouts/admin-alt.layout.vue'),
    children: [
      //Dashboard
      {
        path: "",
        name: 'manager-home',
        component: () => import('../pages/admin/dashboard/index.page.vue'),
      },
    ]
  },
  //Admin
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../components/layouts/admin.layout.vue'),
    children: [
      //Dashboard
      {
        path: "",
        name: 'admin-home',
        redirect: { name: 'admin-dashboard' }
      },
      {
        path: "/admin/dashboard",
        name: 'admin-dashboard',
        component: () => import('../pages/admin/dashboard/index.page.vue'),
      },
      //Users
      {
        path: "/admin/users",
        name: 'admin-users',
        component: () => import('../pages/admin/users/index.page.vue'),
      },
      {
        path: "/admin/users/manage/:id",
        name: 'admin-manage-user',
        component: () => import('../pages/admin/users/manage.page.vue'),
      },
      {
        path: "/admin/help",
        name: 'help',
        component: () => import('../components/layouts/help-center.vue'),
      },
      //Setup
      {
        path: "/admin/setup",
        name: 'admin-setup',
        component: () => import('../pages/admin/setup/index.page.vue'),
      },
      {
        path: "/admin/setup/target/:id",
        name: 'admin-setup-target',
        component: () => import('../pages/admin/setup/target.page.vue'),
      },
      //Dashboard
      {
        path: "/admin/reporting",
        name: 'admin-reporting',
        component: () => import('../pages/admin/reporting/index.page.vue'),
      },
      //analytics
      {
        path: "/admin/analytics",
        name: 'admin-analytics',
        component: () => import('../pages/admin/analytics/index.page.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem("RLE"));
      if (role != null && typeof role.name != 'undefined') {
        if (role.name == "admin") {
          next();
        } else {
          next({ name: 'portal-signin' });
        }
      } else {
        next({ name: 'portal-signin' });
      }
    }
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('../pages/Errors/404.error.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
